export function isErrorArray<T>(
  errors?: T | readonly Error[]
): errors is readonly Error[] {
  return Array.isArray(errors)
    ? errors.every(e => typeof e === 'object' && 'message' in e && 'name' in e)
    : false
}

export function parseGraphqlErrors<T>(
  errors?: T | readonly Error[]
): readonly Error[] {
  return isErrorArray(errors) ? errors : []
}

export const downloadImage = async (imageUrl: string, fileName: string) => {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('style', 'display: none;')
  a.setAttribute('href', url)
  a.setAttribute('download', fileName)
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}
