import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT, footerSchema } from '@ecomm/footer-data'
import { useContentful } from '@ecomm/shared-apollo'

const UK_FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query UK_FOOTER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    footer(id: $id, preview: $preview, locale: $locale) {
      ...footer
    }
  }
`

export const useFooterQuery = (id: string) => {
  const { data } = useContentful(UK_FOOTER_QUERY, { id })
  return footerSchema.parse(data.footer, {
    path: ['crime-in-the-uk', 'useFooterQuery', 'footer']
  })
}
