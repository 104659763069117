import { ContentfulImage } from '@ecomm/contentful/components'
import { ArrowDownSolidIcon } from '@ecomm/shared-icons'
import React from 'react'

import { downloadImage } from '../../helpers/helpers'
import { GridSchema } from '../../schemas/grid'

export default function ImageLinkGrid({
  items
}: {
  readonly items: GridSchema['gridItems']
}) {
  const download = async (event: React.MouseEvent, url: string) => {
    event.preventDefault()
    const urlParts = url.split('/')
    const fileName = urlParts[urlParts.length - 1]
    downloadImage(url, fileName)
  }

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8">
      {items.map(item => (
        <a
          className="rounded-base group relative col-span-1 flex h-32 w-full items-center justify-end overflow-hidden p-4 no-underline shadow-lg md:col-span-1 md:h-48 md:p-6 lg:h-64 lg:p-8 [&_img]:first:!object-contain [&_img]:first:p-8"
          href="#"
          key={`grid-item-${item.title}`}
          onClick={e => download(e, item.image.file.url)}
        >
          <ContentfulImage
            classNameOverride="absolute -z-[1] w-full h-full left-0 top-0"
            description={item.image.description}
            originalHeight={item.image.file.details.image.height}
            originalWidth={item.image.file.details.image.width}
            url={item.image.file.url}
          />
          <span className="z-[2] text-center text-2xl font-medium">
            {item.title}
          </span>
          <span
            className="absolute left-0 right-0 z-[3] flex h-full w-full items-center justify-center bg-black text-white opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-80"
            data-component="HoverScreen"
          >
            <ArrowDownSolidIcon className="h-16 w-16 text-white" />
          </span>
        </a>
      ))}
    </div>
  )
}
