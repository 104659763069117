import { useCallback, useEffect, useState } from 'react'

import {
  type CrimeDataType,
  getSearchTermLocation,
  getYearlyPoliceDataForLocation,
  groupCrimeData
} from '../helpers/policeData'
import * as E from 'fp-ts/lib/Either'
import { logError } from '@ecomm/error-handling'
import { pipe } from 'fp-ts/lib/function'

export function useCrimeData() {
  const [coordinates, setCoordinates] = useState<readonly number[]>([])
  const [crimeData, setCrimeData] = useState<readonly CrimeDataType[]>([])
  const [hasResponse, setHasResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchPoliceData = useCallback(async () => {
    const [lng, lat] = coordinates
    getYearlyPoliceDataForLocation(lat, lng)
      .then(res => {
        setHasResponse(true)
        setIsLoading(false)
        return setCrimeData(groupCrimeData(res.flat().flat()))
      })
      .catch(_ => {
        setHasResponse(true)
        setIsLoading(false)
        return setCrimeData([])
      })
  }, [coordinates])

  useEffect(() => {
    coordinates.length > 0 && fetchPoliceData()
  }, [coordinates])

  const searchCrimeIncidents = useCallback(async (location: string) => {
    setIsLoading(true)
    const geoPos = await getSearchTermLocation(location)
    pipe(
      geoPos,
      E.match(
        err => {
          logError(err)
        },
        geo => setCoordinates(geo.features[0].geometry.coordinates)
      )
    )
  }, [])

  return {
    searchCrimeIncidents,
    crimeData,
    hasResponse,
    isLoading
  }
}
