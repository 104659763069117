import {
  gatsbyImageDataSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

export const iconSchema = gatsbyImageSchema.extend({
  url: z.string(),
  gatsbyImageData: gatsbyImageDataSchema.nullish()
})
