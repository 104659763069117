import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { PriceProvider } from '@ecomm/data-price'
import { ErrorBoundary } from '@ecomm/error-handling'
import { Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header as HeaderRedesign, useHeaderRedesignQuery } from '@ecomm/header'
import {
  ContentfulRichText,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import CrimeLocationBanner from '../components/CrimeLocationBanner'
import CrimeTable from '../components/CrimeTable'
import { GatsbyCitiesGrid } from '../components/GatsbyCitiesGrid'
import PackageCarousel from '../components/PackageCarousel'
import { QuoteWizardEmbedded } from '../components/QuoteWizardEmbedded'
import type {
  LocationCrimesTableSchema,
  SeoNodeSchema
} from '../config/crimeInTheUKResponseSchema'
import { useCrimePageFragment } from '../hooks/useCrimePageFragment'
import { useFooterQuery } from '../hooks/useFooterQuery'
import type { CrimeBannerSchema } from '../schemas/crimeBanner'
import type { LayoutReferenceSchema } from '../schemas/layoutReference'
import type { LinkSchema } from '../schemas/link'
import type { SyntheticPackageSchema } from '../schemas/package'
import type { GatsbyCitiesGridSchema } from '../schemas/page'
import type { SmallTextSchema } from '../schemas/smallText'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly locationCrimesTable: LocationCrimesTableSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

function getCrimeTableOptions(
  body: readonly (readonly string[])[],
  head: readonly string[]
): Options {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: _ => {
        return <CrimeTable body={body} head={head} />
      }
    }
  }
}

export default function CrimePageTemplate<
  T extends { readonly crimeInTheUkPage: U },
  U
>({
  data,
  pageContext: { locale, seoDetails, locationCrimesTable }
}: Props<T>) {
  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails
  const { layout } = useCrimePageFragment(data)
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout

  const footer = useFooterQuery(footerId)

  const headerData = useHeaderRedesignQuery()

  return (
    <TrackingProvider metaTitle={''}>
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <PageToaster />
      <ApplyPromoCode />
      <PromoBannerWrapper type="standard" />
      <HeaderRedesign {...headerData} />
      <main
        className="prose relative z-0 w-full whitespace-pre-line md:prose-md lg:prose-lg"
        id="content"
      >
        <div className="mx-auto mb-16 flex max-w-8xl flex-col gap-16 px-4 pt-8 md:px-8">
          <TrustpilotUKTopBanner className="-mb-8" />
          <ErrorBoundary>
            {components.map((component, index) =>
              match(component)
                .with(
                  {
                    __typename: 'ContentfulCrimeLocationBannerV2'
                  },
                  (data: CrimeBannerSchema) => <CrimeLocationBanner {...data} />
                )
                .with(
                  { __typename: 'ContentfulSmallText' },
                  (data: SmallTextSchema) => (
                    <div className="text-center" key={index}>
                      <ContentfulRichText
                        optionsCustom={getCrimeTableOptions(
                          locationCrimesTable.body,
                          locationCrimesTable.head
                        )}
                        raw={data.text.raw}
                      />
                    </div>
                  )
                )
                .with(
                  { __typename: 'ContentfulLayoutReference' },
                  (_: LayoutReferenceSchema) => <QuoteWizardEmbedded />
                )
                .with(
                  { __typename: 'SyntheticPackages' },
                  (data: SyntheticPackageSchema) => (
                    <PriceProvider
                      locale={locale}
                      skus={data.packages.map(p => p.product)}
                    >
                      <PackageCarousel {...data} />
                    </PriceProvider>
                  )
                )
                .with(
                  { __typename: 'ContentfulGrid' },
                  ({ contentful_id }: GatsbyCitiesGridSchema) => (
                    <GatsbyCitiesGrid id={contentful_id} />
                  )
                )
                .with({ __typename: 'ContentfulLink' }, (data: LinkSchema) => (
                  <a
                    className="order-last mx-auto w-full btn btn-solid-primary md:w-48"
                    href={data.url}
                  >
                    {data.linkText}
                  </a>
                ))
                .otherwise(_ => null)
            )}
          </ErrorBoundary>
        </div>
      </main>
      <Footer {...footer} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query CrimeinTheUKLanding($id: String) {
    crimeInTheUkPage: contentfulGenericLandingPage(id: { eq: $id }) {
      ...crimeInTheUkFragment
    }
  }
`
