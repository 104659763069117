import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { gridSchema } from '../../schemas/grid'
import type { imageLinkSchema } from '../../schemas/imageLink'

export const parsePressKitData = <
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>(
  data: T
): PressKitSchema => {
  const { content } = pressKitSchema.parse(data, {
    path: ['parsePressKitData']
  })

  return {
    content
  }
}

export const pressKitSchema = z.object({
  content: z.object({
    contentful_id: z.string(),
    content: z
      .union([
        gridSchema,
        z.object({
          __typename: z.literal('ContentfulSmallText'),
          text: contentfulRichTextSchema
        })
      ])
      .array()
  })
})

export type PressKitSchema = TypeOf<typeof pressKitSchema>
export type PressKitGridSchema = readonly TypeOf<typeof imageLinkSchema>[]
