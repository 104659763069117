import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const packageSchema = z.object({
  __typename: z.literal('ContentfulPackage'),
  description: contentfulRichTextSchema,
  displayName: z.string(),
  product: z.string(),
  type: z.string(),
  image: gatsbyImageSchema,
  slug: z.string()
})

export const syntheticPackageSchema = z.object({
  __typename: z.literal('SyntheticPackages'),
  packages: packageSchema.array()
})

export type SyntheticPackageSchema = TypeOf<typeof syntheticPackageSchema>
export type PackageSchema = TypeOf<typeof packageSchema>
