import { usePriceContext } from '@ecomm/data-price'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import React, { useState } from 'react'

import { PackageSchema } from '../schemas/package'

type PackageListProps = {
  readonly packages: readonly PackageSchema[]
}

export default function PackageList({ packages }: PackageListProps) {
  const [selectedItem, setSelectedItem] = useState(0)

  const { getPrice, getDiscountedPriceWithServicePlan, formatPrice } =
    usePriceContext()

  return (
    <div className="w-full">
      <ul className="m-0 grid grid-cols-1 gap-4 p-0 md:grid-cols-3 md:gap-8 lg:gap-10">
        {packages.map(
          ({ image, description, displayName, product, slug }, i) => {
            const total = pipe(
              formatPrice(getPrice(product)),
              O.getOrElse(() => '')
            )

            const discountedPrice = pipe(
              formatPrice(getDiscountedPriceWithServicePlan(product)),
              O.getOrElse(() => '')
            )

            return (
              <li
                className={classNames('animate-fadeIn list-none', {
                  'hidden md:list-item': selectedItem !== i
                })}
                data-component="package-card"
                key={`package-item${i}`}
              >
                <div className="relative overflow-hidden rounded-t-base md:order-1">
                  {image ? (
                    <GatsbyImage
                      className="h-full max-h-80 w-full"
                      image={image}
                      loading="eager"
                    />
                  ) : null}
                </div>

                <div className="flex items-center overflow-hidden rounded-b-base bg-neutral-light-50">
                  <div
                    className="flex flex-1 flex-col px-4 py-6  prose-h3:text-2xl prose-ul:my-2 prose-ul:pl-4 md:p-6 prose-h3:md:text-3xl prose-ul:md:pl-5"
                    id="package-info"
                  >
                    <h3 className="mb-3 md:mb-5">{displayName}</h3>
                    {description ? (
                      <ContentfulRichText raw={description.raw} />
                    ) : null}

                    <div className={'mt-0 flex justify-between'}>
                      {!!total ? (
                        <div className="flex gap-2">
                          <span className="text-complementary-red-100">
                            {discountedPrice}
                          </span>
                          <span className="line-through">{total}</span>
                        </div>
                      ) : null}
                      <a
                        className="btn btn-outlined btn-outlined-primary"
                        href={slug}
                      >
                        {slug === 'build-my-system'
                          ? 'Build my system'
                          : 'See details'}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        )}
      </ul>
      <div className="mt-4 flex w-full items-center justify-center gap-4 md:hidden">
        {packages.map((_, index: number) => (
          <button
            className={classNames(
              'h-2 w-2 cursor-pointer rounded-full border-none p-0 transition-colors duration-200 ease-in-out',
              {
                'bg-neutral-black': selectedItem === index,
                'bg-neutral-medium-100': selectedItem !== index
              }
            )}
            data-component={`carousel-dot-button`}
            key={`carousel-dot-${index}`}
            onClick={() => setSelectedItem(index)}
          />
        ))}
      </div>
    </div>
  )
}
