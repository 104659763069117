import { TypeOf, z } from '@simplisafe/ewok'

import { imageLinkSchema } from './imageLink'

export const gridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  headline: z.string(),
  gridItems: imageLinkSchema.array()
})

export type GridSchema = TypeOf<typeof gridSchema>
