import { ErrorBoundary } from '@ecomm/error-handling'
import { useContentful } from '@ecomm/shared-apollo'
import { GRID_QUERY, gridSchema } from '@ecomm/shared-sections'
import { Suspense } from 'react'

import CitiesGrid from './CitiesGrid'

const useGridQuery = (id: string) => {
  const { data } = useContentful(GRID_QUERY, { id })
  return gridSchema.parse(data.grid, {
    path: ['GatsbyCitiesGrid', 'useGridQuery']
  })
}

type Props = {
  readonly id: string
}

function Content({ id }: Props) {
  const grid = useGridQuery(id)
  return <CitiesGrid {...grid.gridItemsCollection} />
}

export function GatsbyCitiesGrid(props: Props) {
  return (
    <Suspense>
      <ErrorBoundary>
        <Content {...props} />
      </ErrorBoundary>
    </Suspense>
  )
}
