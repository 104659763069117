import { ContentfulImage } from '@ecomm/contentful/components'
import { type GridSchema, isLinkArray } from '@ecomm/shared-sections'
import classNames from 'classnames'
import React from 'react'
import { match } from 'ts-pattern'

export default function CitiesGrid({
  items: gridItems
}: GridSchema['gridItemsCollection']) {
  return match(gridItems)
    .when(isLinkArray, items => {
      return (
        <ul className="m-0 grid list-none grid-cols-2 gap-1.5 border-x-0 border-b-0 p-0 px-0 pb-0 md:grid-cols-5 lg:-mt-8 lg:border-t lg:border-solid lg:border-neutral-medium lg:pt-10">
          {items.map(({ linkText: text, icon, url }, i) => (
            <li
              className="list-none overflow-hidden rounded-lg"
              key={`city-item${i}`}
            >
              <a
                className="group relative cursor-pointer no-underline"
                href={url}
              >
                {icon ? (
                  <ContentfulImage classNameOverride="h-40 lg:h-64" {...icon} />
                ) : null}
                <div
                  className={classNames(
                    'absolute bottom-0 flex h-12 w-full items-center justify-center text-lg font-medium',
                    'bg-neutral-medium bg-gradient-to-l from-neutral-medium from-50% to-primary-100 to-50% bg-[length:200%] bg-right transition-all duration-300 ease-in-out',
                    'group-hover:bg-left'
                  )}
                >
                  {text}
                </div>
              </a>
            </li>
          ))}
        </ul>
      )
    })
    .otherwise(() => null)
}
