import { QuizBanner } from '@ecomm/lander'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { quoteWizardEmbeddedSchema } from '../schemas/quotewizardEmbedded'

export function QuoteWizardEmbedded() {
  const data = quoteWizardEmbeddedSchema.parse(
    useStaticQuery(graphql`
      #graphql

      fragment QuoteWizardBaseFragment on ContentfulQuoteWizard {
        __typename
        id: contentful_id
        jebbitUrl
        type
        buttonText
      }
      fragment QuoteWizardExperienceFragment on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulQuoteWizard {
            ...QuoteWizardBaseFragment
          }
        }
      }
      query QuoteWizardQueryCrime {
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "3GT1zG3PE5djEurbN7NQmm" }
        ) {
          ...QuoteWizardBaseFragment
          nt_experiences {
            ...QuoteWizardExperienceFragment
          }
        }
        quoteWizardAsset: contentfulAsset(
          contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
      }
    `)
  )

  return (
    <div data-component="QuoteWizardEmbedded">
      <QuizBanner
        image={data.quoteWizardAsset}
        quoteWizard={data.quoteWizard}
      />
    </div>
  )
}
