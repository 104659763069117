import { ContentfulImage } from '@ecomm/contentful/components'
import { getContentfulImageFromGatsbyData } from '@ecomm/contentful-image-utils'
import { ContentfulRichText } from '@ecomm/shared-components'
import { SearchOutlineIcon } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { ReactElement, ReactNode, useState } from 'react'

import { useCrimeData } from '../hooks/useCrimeData'
import { useLocation } from '../hooks/useLocation'
import { CrimeBannerSchema } from '../schemas/crimeBanner'

export type ButtonIcon = {
  readonly icon: ReactElement | ReactNode
  readonly text: string
  readonly onClick: () => void
}

export type SquareSection = {
  readonly color: string
  readonly count: string
  readonly title: string
}

export type CrimeLocationBannerProps = CrimeBannerSchema & {
  readonly dataComponent?: string
}

function CrimeLocationBanner({
  title,
  enterLocationText = 'Enter your location',
  locationLink,
  descriptionText,
  searchButton,
  disclaimerText,
  backdropImage,
  searchBox,
  searchResultSection,
  noResultSection,
  dataComponent = CrimeLocationBanner.name
}: CrimeLocationBannerProps) {
  const { getLocation, locationText, changeLocation } = useLocation()
  const [searchTitle, setSearchTitle] = useState('')
  const { searchCrimeIncidents, crimeData, hasResponse, isLoading } =
    useCrimeData()

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    searchCrimeIncidents(locationText)
    setSearchTitle(locationText)
  }

  return (
    <div
      className="prose md:prose-md lg:prose-lg"
      data-component={dataComponent}
    >
      {backdropImage ? (
        <div
          className={classNames(
            'relative flex flex-col items-center justify-center gap-6 overflow-hidden rounded-base p-6 text-center prose-p:!text-white md:min-h-[345px] md:p-8 lg:min-h-[512px] lg:gap-10',
            {
              'rounded-b-none': !!disclaimerText,
              'lg:justify-end': !!descriptionText
            }
          )}
        >
          <ContentfulImage
            {...getContentfulImageFromGatsbyData(backdropImage)}
            className="!absolute left-0 top-0 !h-full !w-full"
          />
          <form
            className="relative z-[1] flex w-full max-w-lg flex-col overflow-hidden rounded-base bg-black text-white lg:max-w-xl"
            onSubmit={search}
          >
            <h3 className="my-2 text-2xl text-white md:mb-2.5 md:mt-4 md:text-4xl">
              {title}
            </h3>
            <div className="flex items-stretch gap-4 px-4 py-2 md:p-4">
              <input
                className="mt-px inline-block h-14 w-full max-w-full shrink rounded-base border-none px-4 py-4 text-base font-normal text-neutral-black outline-none"
                id="locationInput"
                name="locationInput"
                onChange={changeLocation}
                placeholder={searchBox.placeholder}
                value={locationText}
              />
              <button
                aria-label={searchButton.buttonText}
                className="flex h-14 w-14 items-center justify-center rounded-base btn btn-solid-primary hover:bg-primary-50"
                disabled={!locationText || isLoading}
                type="submit"
              >
                {isLoading ? (
                  <div
                    className="h-6 w-6 animate-spin rounded-full border-2 border-solid border-transparent border-r-white border-t-white"
                    data-component="Spinner"
                  />
                ) : (
                  <SearchOutlineIcon className="h-10 w-10 text-white" />
                )}
              </button>
            </div>
            <div className="flex justify-between p-4">
              <label className="text-left text-xs font-medium leading-normal md:text-lg">
                {enterLocationText}
              </label>
              <div className="flex justify-end">
                <button
                  aria-label={locationLink.text}
                  className="relative flex cursor-pointer items-center border-0 bg-transparent py-0 pl-4 pr-0 font-medium tracking-wider text-white outline-none [&>img]:w-[15px]"
                  onClick={getLocation}
                  type="button"
                >
                  <span className="pl-[8.5px] text-xs md:text-sm">
                    {locationLink.text}
                  </span>
                </button>
              </div>
            </div>
          </form>
          {descriptionText ? (
            <div className="relative mx-auto text-center text-xs leading-normal prose-p:mb-0 lg:max-w-4xl lg:text-lg">
              <ContentfulRichText {...descriptionText} />
            </div>
          ) : null}
        </div>
      ) : null}
      {disclaimerText ? (
        <div className="w-full rounded-b-base bg-neutral-medium px-4 py-4 text-center text-xs prose-p:mb-0">
          <ContentfulRichText {...disclaimerText} />
        </div>
      ) : null}
      {crimeData.length && !isLoading ? (
        <div className="mt-6" data-component="crime-summary">
          <h2 className="text-center text-heading-3-size leading-h3-height">
            Data for the last 12 months in {searchTitle}
          </h2>
          <div className="my-0 flex flex-col justify-center gap-2 md:flex-row">
            {crimeData.map(({ color, count, title }, i) => (
              <div
                className={`w-full overflow-hidden rounded-base p-4 py-2 text-center text-white`}
                key={`crime-uk-data-${i}`}
                style={{ backgroundColor: color }}
              >
                <p className="text-base">{title}</p>
                <p className="mb-0">{count}</p>
              </div>
            ))}
          </div>
          <div className="mt-4 text-center">
            <ContentfulRichText {...searchResultSection} />
          </div>
        </div>
      ) : null}
      {noResultSection && !crimeData.length && hasResponse ? (
        <div className="mt-6 whitespace-pre-line text-center text-neutral-black">
          <ContentfulRichText {...noResultSection} />
        </div>
      ) : null}
    </div>
  )
}

export default CrimeLocationBanner
