import { ChangeEvent, useCallback, useEffect, useState } from 'react'

const AUGURISK_API_GEOCODING =
  'https://okseu1my87.execute-api.us-east-1.amazonaws.com/default/amazon-location-v2'

export type Position = {
  readonly coords: {
    readonly latitude: number
    readonly longitude: number
  }
}
export function useLocation() {
  const [locationText, setLocationText] = useState<string>('')
  const [position, setPosition] = useState<Position>()

  const getLocation = useCallback(() => {
    navigator?.geolocation?.getCurrentPosition((pos: Position) =>
      setPosition(pos)
    )
  }, [])

  useEffect(() => {
    !!position && fetchCurrentPosition(position)
  }, [position])

  const fetchCurrentPosition = useCallback(async (pos: Position) => {
    const crd = pos.coords
    const res = await fetch(
      `${AUGURISK_API_GEOCODING}?type=position&lat=${crd.latitude}&long=${crd.longitude}&use=single`,
      { method: 'get' }
    )
    const geoPos = await res.json()
    setLocationText(geoPos.Results[0].Place.Label)
  }, [])

  return {
    locationText,
    getLocation,
    changeLocation: (e: ChangeEvent<HTMLInputElement>) => {
      setLocationText(e.target.value)
    },
    setPosition
  }
}
