import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const crimeBannerSchema = z.object({
  __typename: z.literal('ContentfulCrimeLocationBannerV2'),
  title: z.string(),
  searchBox: z.object({
    type: z.string(),
    placeholder: z.string()
  }),
  searchButton: z.object({
    buttonText: z.string()
  }),
  locationLink: z.object({
    internalLink: z.string(),
    type: z.string(),
    text: z.string()
  }),
  enterLocationText: z.string(),
  searchResultSection: contentfulRichTextSchema,
  noResultSection: contentfulRichTextSchema.nullish(),
  descriptionText: contentfulRichTextSchema.nullish(),
  disclaimerText: contentfulRichTextSchema.nullish(),
  backdropImage: gatsbyImageSchema
})
export type CrimeBannerSchema = TypeOf<typeof crimeBannerSchema>
