import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import crimeInTheUKSchema, { layoutSchema } from '../schemas/page'

export const useCrimePageFragment = <
  T extends { readonly crimeInTheUkPage: U },
  U
>(
  query: T
) => {
  const crimeFragment = query.crimeInTheUkPage

  const crimePage = useFragmentWithVariations(crimeFragment, crimeInTheUKSchema)

  const layoutFragment = crimePage.layout

  const layout = useFragmentWithVariations(layoutFragment, layoutSchema)

  const layoutBeforePackageFiltering = layoutSchema.parse(layout)

  const packages = layout.components.filter(
    c => c.__typename === 'ContentfulPackage'
  )
  const NonPackages = layout.components.filter(
    c => c.__typename !== 'ContentfulPackage'
  )

  return {
    ...crimeInTheUKSchema.parse(crimePage),
    layout: {
      ...layoutBeforePackageFiltering,
      components: [
        ...NonPackages,
        { __typename: 'SyntheticPackages', packages: packages }
      ]
    }
  }
}
