module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"dataLayerName":"dataLayer","id":"GTM-MJNZ2VB","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../libs/gatsby-plugins/shared/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../home-landing/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../blog/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../shop/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../media/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../checkout/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../legal/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../city-state/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../monitoring/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../partners/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../misc/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../crime-in-the-uk/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../legacy/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/simplisafe-icon.png","name":"SimpliSafe Home Security Systems","short_name":"SimpliSafe","start_url":"/","theme_color":"#0f2544","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e41f4724aa14673dfd1dc686bf1253a"},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
