import { z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from './contentfulImageQuery'

export const imageLinkSchema = z.object({
  contentful_id: z.string(),
  title: z.string().nullish(),
  url: z.string().nullish(),
  image: contentfulImageQuerySchema
})
