import { format, subMonths } from 'date-fns'

export const getPreviousDates = (
  noOfMonths = 12,
  prevMonth = 0
): readonly string[] => {
  return Array.from({ length: noOfMonths }, (_, i) =>
    format(subMonths(Date.now(), i + prevMonth), 'yyyy-MM')
  )
}
