import classNames from 'classnames'
import React from 'react'

type activeIndexType = readonly number[]

type CrimeTableProps = {
  readonly className?: string
  readonly head: readonly string[]
  readonly body: ReadonlyArray<ReadonlyArray<string>>
  readonly activeIndex?: activeIndexType
}

export default function CrimeTable({
  className = '',
  head,
  body
}: CrimeTableProps) {
  return (
    <div className={classNames('mt-5 max-md:overflow-x-scroll ', className)}>
      <table className="w-full border-collapse border border-solid border-neutral-medium text-center text-sm font-normal text-neutral-black">
        <thead>
          <tr className="p-0 even:bg-neutral-light-50">
            {head.map((text: string) => (
              <th
                className="px-2.5 py-[14px] text-center text-sm font-medium uppercase leading-tight first:w-20 first:pl-4 first:text-left lg:px-6 first:lg:pl-16 last:lg:pr-16"
                key={`head-${text}`}
              >
                {text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="overflow-y-scroll">
          {body.map((data: ReadonlyArray<string>, index: number) => (
            <tr
              className={classNames('p-0 even:bg-neutral-light-50')}
              key={`row-${index}`}
            >
              {data.map((text: string) => (
                <td
                  className="px-2.5 py-[14px] first:w-20 first:pl-4 first:text-left first:text-sm first:font-medium first:uppercase lg:px-6 first:lg:pl-16 last:lg:pr-16 [&:nth-child(2)]:bg-neutral-light-50 [&:nth-child(2)]:mix-blend-darken"
                  key={`data-${text}`}
                >
                  {text}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
