import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { iconSchema } from './icon'

export const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema,
  icon: iconSchema.nullish()
})
export type SmallTextSchema = TypeOf<typeof smallTextSchema>
